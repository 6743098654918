import React, { useState, useEffect } from "react"
import { Nav, Container, Col, Row } from "react-bootstrap"
import Logo from "../../images/icons/logo.svg"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import GetLink from "../functions/GetLink"
import BurgerMenu from "./BurgerMenu"
// import SearchOverlay from '../SearchOverlay/SearchOverlay';
import $ from "jquery"
/**
 * Assets
 */
import "./styles/_index.scss"

const myaccountURL = process.env.GATSBY_MYACCOUNT_URL || ""



const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      glstrapi {
        allMenus(sort: "Sorting:asc") {
          id
          Label
          Primary_URL
          Secondary_URL
          Show_In_Top_Nav
          Sorting
          Main_Parent {
            id
            Label
            Primary_URL
            Secondary_URL
          }
          Sub_Parent {
            id
            Label
            Primary_URL
            Secondary_URL
          }
        }
      }
    }
  `)

  const menu_data = data.glstrapi.allMenus

  // Search Overlay
  const [showSearchOverlay, setShowSearchOverlay] = useState(false)

  const handleSearchOverlayClose = () => setShowSearchOverlay(false)
  const handleSearchOverlayShow = () => setShowSearchOverlay(true)
  // Search Overlay

  //Set Scroll
  const [scroll, setScroll] = useState(false)
  const [showSubmenu, setShowSubMenu] = useState()
  const [showSubmenuLandlord, setshowSubmenuLandlord] = useState()

  const modalFunction = (url) =>{
    if(url === "our-culture"){
      setShowSubMenu(true);
      setshowSubmenuLandlord(false)
    }else if(url === "landlords"){
      setShowSubMenu(false);
      setshowSubmenuLandlord(true)
    }
  }

  useEffect(() => {
    $(".main-nav .menu-link a").click(function () {
      $(window).scrollTop(0)
    })
    try {
      window.addEventListener("scroll", () => {
        setScroll(window.scrollY > 1)
      })
    } catch { }
    window.scrollTo(0, 0)

    // Start of HubSpot Embed Code
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = 'hs-script-loader';
    script.async = true;
    script.defer = true;
    script.src = '//js-eu1.hs-scripts.com/144127466.js';
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
    // End of HubSpot Embed Code
  }, [])

  return (
    <header className={`header fixed ${scroll ? "scrolled" : ""}`}>
      <Container fluid>
        <div className="d-flex justify-content-between align-items-center">
          <div lg={2} md={6} className="logo-section">
            <Link to="/" className="navbar-brand">
              <img src={Logo} alt="logo" className="brand-logo" />
            </Link>
          </div>
          <div className="d-none d-xl-block">
            <Nav className="mr-auto justify-content-center">
              <ul className="main-nav">
                {menu_data.map((menuitem, key) => {
                  if (menuitem.Show_In_Top_Nav === true && menuitem.Primary_URL === "our-culture" ) {
                    return (
                      <li className="menu-link" onMouseOver={() => modalFunction(menuitem.Primary_URL)} >
                        <GetLink link={menuitem} />
                        <div className={showSubmenu ? "dropdown-link open" : "dropdown-link"} onMouseLeave={() => setShowSubMenu(!showSubmenu)}>
                          <ul className="submenu">
                            {menu_data.map((submenu, key) => {
                              if (submenu.Main_Parent && submenu.Sub_Parent === null && submenu.Main_Parent.Label === menuitem.Label && submenu.Secondary_URL == null) {
                                return (
                                  <li><GetLink link={submenu} /></li>
                                )
                              }
                            })}
                          </ul>
                        </div>
                      </li>
                    )
                  } else if(menuitem.Show_In_Top_Nav === true &&  menuitem.Primary_URL === "landlords"){
                    return (
                      <li className="menu-link" onMouseOver={() => modalFunction(menuitem.Primary_URL)} >
                        <GetLink link={menuitem} />
                        <div className={showSubmenuLandlord ? "dropdown-link open" : "dropdown-link"} onMouseLeave={() => setshowSubmenuLandlord(!showSubmenuLandlord)}>
                          <ul className="submenu">
                            {menu_data.map((submenu, key) => {
                              if (submenu.Main_Parent && submenu.Sub_Parent === null && submenu.Main_Parent.Label === menuitem.Label && submenu.Secondary_URL == null) {
                                return (
                                  <li><GetLink link={submenu} /></li>
                                )
                              }
                            })}
                          </ul>
                        </div>
                      </li>
                    )
                  }else if (menuitem.Show_In_Top_Nav === true) {
                    return (
                      <li className="menu-link" onMouseOver={() => { setShowSubMenu(false) }}>
                        <GetLink link={menuitem} />
                      </li>
                    )
                  }
                })}
              </ul>
            </Nav>
          </div>
          <div className="toggle-nav-holder justify-content-end">
            <div className="header-icons">
              {/* <div className="repair-wrap">
                <a href="https://davidastburys.fixflo.com/Auth/HomeIssueCreate" target={`_blank`}>
                  <i
                    className="icon-repair"
                    onClick={handleSearchOverlayShow}
                  ></i>
                  <div className="icon-sm">report a repair</div>
                </a>
              </div> */}
              {/* <Link to={myaccountURL} className="sb-myacc"><i className="icon-user"></i></Link> */}
              <BurgerMenu menu_data={menu_data} />
            </div>
          </div>
        </div>
      </Container>
      {/* <ModalNav active={activeModal} toggle={setActiveModal} /> */}
    </header>
  )
}

export default Header
